'use strict';

addEventListener('direct-upload:initialize', event => {
  const { target, detail } = event
  const { id, file } = detail

  target.parentNode.insertAdjacentHTML("beforebegin",
    `
      <div class="progress mb-1" id="direct-upload-${id}">
        <div id="direct-upload-progress-${id}" class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    `
  )

  target.classList.add('invisible');
})

addEventListener('direct-upload:start', event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)

  // TODO: Do something here to indicate upload start
})

addEventListener('direct-upload:progress', event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)

  progressElement.style.width = `${progress}%`
})

addEventListener('direct-upload:error', event => {
  event.preventDefault()

  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)

  element.classList.add('is-invalid')
})

