// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")

// App specific components
import "components/direct_upload"

// Vendor libraries
import Typed from 'typed.js'

import "slick"
import "jquery.fancybox"
import "jquery.cubeportfolio"
import "./bootstrap_custom"


import "theme/hs.core"

// Theme components
import "theme/components/hs.header"
import "theme/components/hs.svg-injector"
import "theme/components/hs.slick-carousel"
import "theme/components/hs.cubeportfolio"

document.addEventListener('turbolinks:load', function(){
  if (document.querySelector('.u-text-animation')) {
    var typed = new Typed(".u-text-animation.u-text-animation--typing", {
      strings: ["rozvíjať", "podporovať", "skvalitňovať"],
      typeSpeed: 60,
      loop: true,
      backSpeed: 25,
      backDelay: 1500
    });
  }
})